import React from "react";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import useRestoranDetay from "../hooks/useRestoranDetay";
import MenuItem from "../components/MenuItem";
import Restorant from "../components/Restorant";

export default function RestaurantDetails() {
  const { id } = useParams();

  const restoranDetay = useRestoranDetay(id);

  if (!id) {
    return (
      <>
        <Navbar />
        <div className="flex flex-1 items-center">
          <h1>Restoran bulunamadı</h1>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className="flex flex-1 flex-col items-center">
        <div className="container">
          <div className="row">
            {restoranDetay.restoranDetay?.restoran && (
              <div className="col-12 ml-4">
                <Restorant
                  id={restoranDetay.restoranDetay?.restoran.id}
                  name={restoranDetay.restoranDetay?.restoran.name}
                  location={restoranDetay.restoranDetay?.restoran.address}
                  logo={restoranDetay.restoranDetay?.restoran.imgUrl}
                  restorantDetail={true}
                  key={restoranDetay.restoranDetay?.restoran.id}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 ml-4">
              <div className="flex flex-row overflow-auto gap-8">
                {restoranDetay.restoranDetay?.categories.map((category) => (
                  <a href={"#" + category.name} key={category.id}>
                    <h1 className="w-auto font-bold text-gray-900 border border-s-slate-100 p-4">
                      {category.name}
                    </h1>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ml-4">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                Menü
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ml-4"></div>
          </div>
          <div className="row">
            {restoranDetay.isLoading ? (
              <div className="flex flex-1 items-center">
                <h1>Yükleniyor...</h1>
              </div>
            ) : (
              restoranDetay.restoranDetay?.categories.map((category) => (
                <div
                  key={category.id}
                  className="border-b border-s-slate-400 "
                  id={category.name}
                >
                  <h1 className="text-2xl font-bold tracking-tight text-gray-900 compnayName ml-4 pt-8">
                    {category.name}
                  </h1>
                  {restoranDetay.restoranDetay?.menu
                    // eslint-disable-next-line eqeqeq
                    .filter((menu) => menu.category_id == category.id)
                    .map((menu, index) => (
                      <MenuItem key={index} menuItem={menu} />
                    ))}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}
