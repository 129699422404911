import React from "react";
import Restorant from "./Restorant";
import useRestoranlar from "../hooks/useRestoranlar";

export default function Restorants() {
  const restoranlar = useRestoranlar();
  return (
    <>
      <div className="flex flex-1 justify-center self-center mb-8">
        <div className="w-9/12">
          <h3 className="flex flex-1 font-bold text-[36px] mb-8">
            Restoranlar
          </h3>
          <div className="flex flex-1 flex-wrap gap-12 items-center justify-center self-center">
            {!restoranlar.isLoading &&
              restoranlar.restoranlar.map((restoran) => (
                <Restorant
                  key={restoran.id}
                  id={restoran.id}
                  name={restoran.name}
                  location={restoran.address}
                  logo={restoran.imgUrl}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
