import { useEffect, useState } from "react";
import config from "../config";
import { useQuery } from "react-query";
import { RestoranDetay } from "../models/RestoranDetayModel";

const useRestoranDetay = (id: string | undefined) => {
  const [restoranDetay, setRestoranDetay] = useState<
    RestoranDetay | undefined
  >();

  const { data, error, isLoading } = useQuery(
    "restoranDetay",
    () =>
      fetch(`${config.API_URL}restoran?restoranId=${id}`).then((res) =>
        res.json()
      ),
    {
      refetchInterval: 60000,
    }
  );

  useEffect(() => {
    if (data) {
      setRestoranDetay(data.success);
    }
  }, [data]);

  return { restoranDetay, error, isLoading };
};

export default useRestoranDetay;
