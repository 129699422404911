import { useEffect, useState } from "react";
import config from "../config";
import { Restoranlar } from "../models/RestoranModel";
import { useQuery } from "react-query";

const useRestoranlar = () => {
  const [restoranlar, setRestoranlar] = useState<Restoranlar[]>([]);

  const { data, error, isLoading } = useQuery(
    "restoranlar",
    () => fetch(`${config.API_URL}restoranlar`).then((res) => res.json()),
    {
      refetchInterval: 60000,
    }
  );

  useEffect(() => {
    if (data) {
      setRestoranlar(data.success);
    }
  }, [data]);

  return { restoranlar, error, isLoading };
};

export default useRestoranlar;
