import React from "react";
import Navbar from "../components/Navbar";
import Restorants from "../components/Restorants";

export default function Home() {
  return (
    <>
    <Navbar />
    <Restorants />
    </>
  );
}
