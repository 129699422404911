import React from "react";
import { Link } from "react-router-dom";

interface RestorantProps {
  id: number;
  name: string;
  location: string;
  logo: string;
  restorantDetail?: boolean;
}

export default function Restorant(Props: RestorantProps) {
  const { id, name, location, logo, restorantDetail } = Props;
  return (
    <Link to={restorantDetail ? "" : "restaurant/" + id} className="">
      <div
        className={
          restorantDetail
            ? "w-[95%] md:min-h-[388px] p-8"
            : "w-[300px] md:min-h-[388px] bg-white border-gray-200 rounded-xl shadow-md shadow-slate-300 p-8"
        }
      >
        <img
          src={logo}
          alt=""
          className={
            "rounded-lg md:m-h-[50px] max-h-52 max-w-sm w-full object-fill mb-4 bg-cover"
          }
        />

        <h5 className="pb-2 text-2xl font-bold tracking-tight text-gray-900 compnayName">
          {name}
        </h5>
        <p className="pb-3 font-normal text-gray-700 dark:text-gray-400">
          {location}
        </p>
      </div>
    </Link>
  );
}
