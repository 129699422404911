import React from "react";
import { Menu } from "../models/RestoranDetayModel";

interface MenuItemProps {
  menuItem: Menu;
}

export default function MenuItem(Props: MenuItemProps) {
  const { menuItem } = Props;
  return (
    <div className="flex flex-row items-center bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-4">
      {menuItem.image === "" ? (
        <div className="object-cover rounded-lg h-[126px]" />
      ) : (
        <img
          className="object-cover w-[150px] rounded-lg h-[126px] m-2"
          src={menuItem.image}
          alt={menuItem.name}
        />
      )}

      <div className="flex flex-col justify-between p-4 leading-normal">
        <h6 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
          {menuItem.name}
        </h6>
        <p className="mb-3 font-normal text-gray-700">
          {menuItem.description}
        </p>
        <p className="mb-2 text-md tracking-tight text-gray-900">
          {menuItem.price} TL
        </p>
      </div>
    </div>
  );
}
